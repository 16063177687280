body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/************* ELEMENT SELECTORS *************/

p {
  font-size: 150%;
}

table {
  width: 100%;
  border-collapse: collapse;
}


td {
  width: 50%;
}


th {
  background-color: #ddd;
}

button {
  font-size: 2vh;
}

/************* CLASS SELECTORS *************/

.parent {
  width: 100%;
  position: absolute;
  top: 25vh;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spacer {
  padding: 10px
}

.landingPageDescription {
  width: 75%;
}

.buttonText {
  font-size: 1.8vh;
  margin : 0%;
}

.tileDescription {
  font-size: 2vh;
}

.left {
  /* background-color: blue;
  color: white; */
  flex: 2;
  padding: 20px;
}

.right {
  background-color: #e91e63;
  color: white;
  flex: 1;
  padding: 20px;
}

.button-div {
  /* display: flex;
  align-self: left; */
  padding-top: 15px;
  padding-left: 5px;
  height: 1.5vw;
  padding-bottom: 3vh;
}

.divider-root {
  display: grid;
  grid-template-rows: 2fr 1fr;
}


.scene-tooltip {
  color : black;
}