#cbioPortalPlots {
    width : 50%;
    float : left;
}

#cbioPortalTableDiv {
    overflow-y: scroll;
    height : 60vh;
    width : 40%;
    float : left;
}

/* For some reason, these specific css selectors have to be here */
th, td {
    text-align: center;
    padding: 8px;
    border: 1px solid black;
    font-size: 2vh;
}
